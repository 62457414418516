import {useStaticQuery, graphql} from 'gatsby';

const IndexImages = () => {
  const data = useStaticQuery (graphql`
query indexQuery {
  
  elcrimen:file(relativePath: {eq: "elcri.men.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality:90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  hoyodecrimen:file(relativePath: {eq: "mapa.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 95, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  hoyodesmog:file(relativePath: {eq: "fulls/hoyodesmog_square.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

    mxmaps:file(relativePath: {eq: "mxmaps.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 99, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

   
  
}
`);
  return data;
};

export default IndexImages;
