import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import IndexImages from "../components/IndexImages";
import Newsletter from "../components/Newsletter";
import LocLink from "../components/LocLink";
import Img from "gatsby-image";
//import { GatsbyImage, getImage } from "gatsby-plugin-image"

import social_image from "../images/social.png";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

function IndexPage() {
  const indexImages = IndexImages();
  const {t} = useTranslation();
  return (
    <Layout>
      <SEO
        keywords={["Diego Valle", "Diego Valle-Jones"]}
        title="Home"
        description={t("The official website of Diego Valle-Jones. Explore my collection of stunning data visualizations and analyses by clicking the blue link ⬆️ up above")}
        image={social_image}
      />
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        <section className="text-center">
          <h1 className="inline-block my-2 p-2 xl:text-5xl">
            <Trans i18nKey="Hi there!">
              Hi there! I'm
              <span
                style={{
                  backgroundImage:
                    "linear-gradient(0deg,rgba(255, 224, 0,.6) 35%,transparent 0)",
                }}
              >
                Diego
              </span>
            </Trans>
          </h1>
        </section>

        <section
          className="text-xl"
          style={{ fontFamily: "'Cooper Hewitt', sans-serif" }}
        >
          <p className="p-1">
          <Trans i18nKey="index_intro">
            You have just landed on my personal homepage. I'm passionate about
            programming, data science, and visualization… and chances are,
            you've already heard about me from either</Trans>
            
            <a rel="me" href={t("https://elcri.men/en/")}>
              elcri.men
            </a>
            {' '}<Trans>or</Trans>{' '}
            <a rel="me" href={t("https://hoyodecrimen.com/en/")}>
              hoyodecrimen.com
            </a>
          </p>

          <p className="p-1">
          <Trans i18nKey="index_while">
            While you're here, you can follow me on{" "}
            <a rel="me" href="https://twitter.com/diegovalle">
              twitter
            </a>
            , track some of the projects I’m working on at my{" "}
            <a rel="me" href="https://github.com/diegovalle">
              github
            </a>{" "}
            account, or <LocLink to="/contact/">get in touch</LocLink> with me. Thanks for
            visiting!
          </Trans>
          </p>
        </section>
      </main>

      <section className="text-black">
        <div className="flex flex-col flex-1 md:justify-center max-w-6xl mx-auto px-4 py-4 md:p-4 w-full">
          <h2 className="text-3xl text-black"><Trans>Some of my Projects:</Trans></h2>
        </div>

        <section className="py-8 px-4">
          <div className="flex flex-wrap max-w-6xl mx-3 container mx-auto px-4">
            <div className="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 lg:px-20 xl:px-20 sm:px-2 md:px-2 mb-6 py-0">
              <a href={t('https://elcri.men/en/')} aria-label="elcri.men screenshot">
                <Img
                  fluid={indexImages.elcrimen.childImageSharp.fluid}
                  sizes={{
                    ...indexImages.elcrimen.childImageSharp.fluid,
                    aspectRatio: 1,
                  }}
                  loading="eager"
                  imgStyle={{ overflow: "hidden" }}
                  className="rounded-full border border-gray-400 object-contain"
                  fadeIn={false}
                  title="El Crimen - Monthly report of Mexican crime trends"
                  alt="El Crimen - Monthly report of Mexican crime trends"
                />
              </a>
              <hr className="mt-8 mb-2" />
            </div>

            <div className="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 lg:px-20 xl:px-20  sm:px-2 md:px-2 mb-6 py-0">
              <a
                href={t("https://hoyodecrimen.com/en/")}
                aria-label="hoyodecrimen screenshot"
              >
                <Img
                  fluid={indexImages.hoyodecrimen.childImageSharp.fluid}
                  sizes={{
                    ...indexImages.hoyodecrimen.childImageSharp.fluid,
                    aspectRatio: 1,
                  }}
                  loading="lazy"
                  imgStyle={{ overflow: "hidden" }}
                  className="rounded-full border border-gray-400"
                  fadeIn={false}
                  title="HoyodeCrimen.com - Mexico City crime maps and information"
                  alt="HoyodeCrimen.com - Mexico City crime maps and information"
                />
              </a>
              <hr className="mt-8 mb-2" />
            </div>

            <div className="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 lg:px-20 xl:px-20 sm:px-2 md:px-2 mb-6">
              <a
                href={t("https://www.diegovalle.net/mxmaps/")}
                aria-label="mxmaps screenshot"
              >
                <Img
                  fluid={indexImages.mxmaps.childImageSharp.fluid}
                  sizes={{
                    ...indexImages.mxmaps.childImageSharp.fluid,
                    aspectRatio: 1,
                  }}
                  imgStyle={{ overflow: "hidden" }}
                  loading="lazy"
                  className="rounded-full border border-gray-400"
                  fadeIn={false}
                  title="mxmaps - The easiest way to create maps of Mexico"
                  alt="mxmaps - The easiest way to create maps of Mexico"
                />
              </a>
            </div>

            <div className="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 lg:px-20 xl:px-20 sm:px-2 md:px-2 mb-6">
              <a
                href={t("https://hoyodesmog.diegovalle.net/")}
                aria-label="hoyodesmog screenshot"
              >
                <Img
                  fluid={indexImages.hoyodesmog.childImageSharp.fluid}
                  sizes={{
                    ...indexImages.hoyodesmog.childImageSharp.fluid,
                    aspectRatio: 1,
                  }}
                  imgStyle={{ overflow: "hidden" }}
                  loading="lazy"
                  className="rounded-full border border-gray-400 object-contain"
                  fadeIn={false}
                  title="HoyodeSmog - Mexico City weather and pollution data"
                  alt="HoyodeSmog - Mexico City weather and pollution data"
                />
              </a>
            </div>
          </div>
        </section>

        {/* <div className="">
          <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
            <div className="flex mb-4 h-full  flex-wrap">
              <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                <h2>elcri.men</h2>
                <p>
                  Up-to-date crime information at the state and municipality levels for all of Mexico
                  {' '}
                  <a href="https://elcri.men/en/">https://elcri.men/en/</a>
                  {' '}
                  and Spanish
                  {' '}
                  <a href="https://elcri.men">https://elcri.men</a>
                </p>
              </div>
              <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                <a
                  href="https://elcri.men/en/"
                  aria-label="elcri.men screenshot"
                >
                  <Img
                    fluid={indexImages.elcrimen.childImageSharp.fluid}
                    sizes={{
                      ...indexImages.elcrimen.childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    loading="lazy"
                    className="rounded-full"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className=" border">
          <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full   border-r border-l">
            <div className="flex mb-4 h-full  flex-wrap">
              <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                <a
                  href="https://hoyodecrimen.com/en"
                  aria-label="hoyodecrimen screenshot"
                >
                  <Img
                    sizes={{
                      ...indexImages.hoyodecrimen.childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    loading="lazy"
                    className="rounded-full"
                  />
                </a>
              </div>
              <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                <h2>hoyodecrimen.com</h2>
                <p>
                  A web app and
                  {' '}
                  <a href="https://hoyodecrimen.com/api/">API</a>
                  {' '}
                  providing crime information for Mexico City
                  {' '}
                  <a href="https://hoyodecrimen.com">
                    https://hoyodecrimen.com
                  </a>
                  {' '}
                  and in English
                  {' '}
                  <a href="https://hoyodecrimen.com/en">
                    https://hoyodecrimen.com/en
                  </a>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div className="">
          <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
            <div className="flex mb-4 h-full  flex-wrap">
              <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                <h2>mxmaps</h2>
                <p>
                  An R package for creating choropleths of Mexico
                  {' '}
                  <a href="https://www.diegovalle.net/mxmaps/">
                    https://www.diegovalle.net/mxmaps/
                  </a>
                </p>
              </div>
              <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">

                <a
                  href="https://www.diegovalle.net/mxmaps/"
                  aria-label="mxmaps screenshot"
                >
                  <Img
                    fluid={indexImages.mxmaps.childImageSharp.fluid}
                    sizes={{
                      ...indexImages.mxmaps.childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    loading="lazy"
                    className="rounded-full"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="border">
          <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
            <div className="flex mb-4 h-full  flex-wrap">
              <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">

                <a
                  href="https://hoyodesmog.diegovalle.net/"
                  aria-label="hoyodesmog screenshot"
                >
                  <Img
                    fluid={indexImages.hoyodesmog.childImageSharp.fluid}
                    sizes={{
                      ...indexImages.hoyodesmog.childImageSharp.fluid,
                      aspectRatio: 1,
                    }}
                    loading="lazy"
                    className="rounded-full"
                  />
                </a>
              </div>
              <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                <h2>hoyodesmog</h2>
                <p>
                  A web app and
                  {' '}
                  <a href="https://hoyodesmog.diegovalle.net/aire.zmvm.html">
                    r package
                  </a>
                  {' '}
                  providing real-time pollution data for Mexico City
                  {' '}
                  <a href="https://hoyodesmog.diegovalle.net/es/">
                    https://hoyodesmog.diegovalle.net/es/
                  </a>
                  ,  also in English
                  {' '}
                  <a href="https://hoyodesmog.diegovalle.net/">
                    https://hoyodesmog.diegovalle.net/
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </section>

      <Newsletter />
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "header", "newsletter"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
